<template>
    <div class="modal-body">
        <h2 style="text-align:center;">Create Coupon</h2><br/>
        <form v-on:submit.prevent="addCoupon" :data-vv-scope="'add-coupon'">
        <div>
            <div class="row">
                <div class="col-xs-12 col-sm-6">
                    <input
                    type="text"
                    placeholder="Coupon Name"
                    name="name"
                    v-model="coupon.name"
                    v-validate="'required'"
                    maxlength="23"
                    :class="{'input': true, 'is-danger': errors.has('add-coupon.name') }"
                    >
                </div>
                <div class="col-xs-12 col-sm-6">
                    <input
                    type="text"
                    placeholder="Coupon Link"
                    name="link"
                    v-model="coupon.link"
                    v-validate="{
                        required: true,
                        url: { require_protocol: true }
                      }"
                    maxlength="1024"
                    :class="{'input': true, 'is-danger': errors.has('add-coupon.link') }"
                    >
                </div>
                <div class="col-xs-12 col-sm-12">
                    <textarea
                    v-model="coupon.description"
                    placeholder="Special Notes"
                    class="input textarea"
                    name="description"
                    rows="3"
                    v-validate="'required'"
                    maxlength="2000"
                    :class="{'input': true, 'is-danger': errors.has('add-coupon.description') }"
                    ></textarea>
                </div>
            </div>
            <h4 class="text-center">Add Coupon Image</h4>
            <div class="row">
            <div class="col-xs-12 croppa-tip"></div>
            <div
                class="col-xs-12 col-sm-12 col-md-12 text-center"
                v-for="(cropper, i) in croppers"
                :key="'coupon-image-' + i"
            >
                <div class="upload-remove-container">
                <croppa
                    :width="400"
                    :heigh="300"
                    v-model="croppers[i]"
                    placeholder="Select Image"
                    initial-size="contain"
                    :placeholder-font-size="25"
                    :show-remove-button="false"
                    :initial-image="initialImages[i]"
                ></croppa>
                <button
                    v-if="croppers[i] && croppers[i].hasImage()"
                    class="upload-remove"
                    type="button"
                    @click="croppers[i].remove()"
                ></button>
                </div>
            </div>
            </div>
        </div>
        <input
            type="submit"
            class="btn-lg-green m0"
            :value="editCouponFlag?'UPDATE COUPON':'ADD COUPON'"
            :disabled="busy"
        >
        </form>
    </div>
</template>
<script type="text/javascript">
export default {
  name: 'CouponPopup',
  props: ['popupId', 'couponDetails', 'editCouponFlag', 'brandId'],
  data () {
    return {
      busy: false,
      coupon: {
        name: '',
        link: '',
        description: '',
        isActive: 1,
        images: [],
        brandId: this.brandId,
        type: 'PICKL'
      },
      croppers: new Array(1),
      couponImages: new Array(1),
      limitPerPage: 100,
      offset: 0,
      couponSections: [],
      initialImages: new Array(1)

    }
  },
  methods: {

    generateImage (index) {
      let url = this.croppers[index].generateDataUrl()
      if (!url) {
        return
      }
      this.couponImages[index] = url
    },

    addCoupon () {
      for (var i = 0; i < 1; ++i) this.generateImage(i)
      this.$validator.validateAll('add-coupon').then((result) => {
        console.log(result)
        if (result && !this.busy) {
          this.busy = true
          if (this.editCouponFlag) {
            this.$store.dispatch('updateCoupon', this.coupon).then((response) => {
              if (this.couponImages.length > 0) {
                this.uploadCouponImage(this.coupon.id, this.couponImages[0])
              } else {
                this.busy = false
                this.$router.push('/brand/coupons')
              }
            }, (response) => {
              this.$emit('failed')
              for (var key in response.body) {
                this.$notify({ type: 'error', text: response.body[key][0] })
                let field = this.$validator.fields.find({ name: key, scope: 'add-coupon' })
                if (field) {
                  this.$validator.errors.add({
                    id: field.id,
                    field: key,
                    msg: response.body[key][0],
                    scope: 'add-coupon'
                  })
                }
              }
              this.busy = false
            })
          } else {
            this.$store.dispatch('saveCoupon', this.coupon).then((response) => {
              if (this.couponImages.length > 0) {
                this.uploadCouponImage(response.data.id, this.couponImages[0])
              } else {
                this.busy = false
                this.$router.push('/brand/coupons')
              }
            }, (response) => {
              this.$emit('failed')
              for (var key in response.body) {
                this.$notify({ type: 'error', text: response.body[key][0] })
                let field = this.$validator.fields.find({ name: key, scope: 'add-coupon' })
                if (field) {
                  this.$validator.errors.add({
                    id: field.id,
                    field: key,
                    msg: response.body[key][0],
                    scope: 'add-coupon'
                  })
                }
              }
              this.busy = false
            })
          }
        } else {
          console.log(this.errors)
          this.$emit('failed')
        }
      })
    },
    uploadCouponImage (couponId, image) {
      if (typeof image !== 'undefined') {
        this.$store
          .dispatch('uploadCouponImage', {
            couponId: couponId,
            image: image
          })
          .then(
            response => {
              this.$notify({
                type: 'success',
                text: 'Coupon has been saved successfully'
              })
              this.busy = false
              this.$router.push('/brand/coupons')
            },
            response => {}
          )
      } else {
        this.$notify({
          type: 'success',
          text: 'Coupon has been saved successfully'
        })
        this.busy = false
        this.$router.push('/brand/coupons')
      }
    },

    resetForm () {
      this.croppers.forEach((x) => x.remove())
      let croppers = this.croppers
      Object.assign(this.$data, (this.$options.data).apply(this))
      this.croppers = croppers
      this.$nextTick(() => this.$validator.errors.clear('add-coupon'))
    },

    success () {
      (this.$refs.popupCloseButton).click()
      this.$emit('added')
      this.busy = false
    }
  },
  watch: {
    couponDetails () {
      this.coupon.name = this.couponDetails.name
      this.coupon.link = this.couponDetails.link
      this.coupon.description = this.couponDetails.description
      this.coupon.type = this.couponDetails.type
      this.coupon.id = this.couponDetails.id
      this.coupon.brandId = this.couponDetails.brandId
      var i
      this.coupon.images = []
      this.initialImages = []
      for (i = 0; i < 1; i++) {
        this.croppers[i].refresh()
        if (this.editCouponFlag && (typeof this.couponDetails.image !== 'undefined')) {
          let image = new Image()
          image.setAttribute('crossorigin', 'anonymous')
          image.src = this.couponDetails.image
          this.initialImage = image
          this.initialImages[i] = image
        }
      }
    }
  }

}
</script>
<style scoped>

.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectcouponDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}
.textarea {
  width: 100%;
}
</style>
